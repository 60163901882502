var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"add-bar"},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.addBanner}},[_vm._v("新增")])],1),_c('el-table',{staticStyle:{},attrs:{"data":_vm.tableData,"stripe":""}},[_c('el-table-column',{attrs:{"prop":"","label":"序号","width":"180","type":"index"}}),_c('el-table-column',{attrs:{"prop":"image","label":"banner图"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.image)?_c('el-image',{staticClass:"image-one",attrs:{"src":_vm.projectName +
            '/common/backstage/file/fileDownLoad?filePath=' +
            encodeURIComponent(scope.row.image),"preview-src-list":[
            _vm.projectName +
              '/common/backstage/file/fileDownLoad?filePath=' +
              encodeURIComponent(scope.row.image) ]}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"isJump","label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.status==1?'上架':'下架'))])]}}])}),_c('el-table-column',{attrs:{"prop":"isJump","label":"是否跳转"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.isJump==true?'是':'否'))])]}}])}),_c('el-table-column',{attrs:{"prop":"jumpUrl","label":"跳转图片"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.jumpUrl)?_c('el-image',{staticClass:"image-two",attrs:{"src":_vm.projectName +
            '/common/backstage/file/fileDownLoad?filePath=' +
            encodeURIComponent(scope.row.jumpUrl),"preview-src-list":[
            _vm.projectName +
              '/common/backstage/file/fileDownLoad?filePath=' +
              encodeURIComponent(scope.row.jumpUrl) ]}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"operation","label":"操作","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.status==1)?_c('el-link',{attrs:{"underline":false,"type":"primary"},on:{"click":function($event){return _vm.isGrounding(scope.row)}}},[_vm._v("下架")]):_vm._e(),(scope.row.status==0)?_c('el-link',{attrs:{"underline":false,"type":"danger"},on:{"click":function($event){return _vm.isGrounding(scope.row)}}},[_vm._v("上架")]):_vm._e(),_c('el-link',{staticClass:"ml10r",attrs:{"underline":false,"type":"primary"},on:{"click":function($event){return _vm.editOption(scope.row)}}},[_vm._v("编辑")]),_c('el-link',{staticClass:"ml10r",attrs:{"underline":false,"type":"danger"},on:{"click":function($event){return _vm.deleteOption(scope.row)}}},[_vm._v("删除")])]}}])})],1),_c('div',{staticClass:"footer"},[_c('el-pagination',{attrs:{"current-page":_vm.listQuery.current,"page-size":_vm.listQuery.size,"layout":"total, prev, pager, next, jumper","total":_vm.listQuery.total},on:{"current-change":_vm.handleCurrentChange}})],1),_c('div',{staticClass:"dialogVisible"},[_c('el-dialog',{attrs:{"title":_vm.titleName,"visible":_vm.adDialogVisible,"width":"30%"},on:{"update:visible":function($event){_vm.adDialogVisible=$event}}},[_c('div',[_c('el-form',{attrs:{"label-position":"right","label-width":"130px"},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}},[_c('el-form-item',{attrs:{"label":"banner图："}},[_c('div',{staticClass:"upload-image1"},[_c('el-upload',{staticClass:"avatar-uploader1",attrs:{"action":_vm.projectName + '/common/backstage/file/fileupload',"headers":_vm.headers,"show-file-list":false,"on-success":_vm.handleAvatarSuccess,"before-upload":_vm.beforeAvatarUpload}},[(_vm.form.image)?_c('el-image',{staticClass:"avatar1",attrs:{"src":_vm.projectName +
            '/common/backstage/file/fileDownLoad?filePath=' +
            encodeURIComponent(_vm.form.image),"preview-src-list":[
            _vm.projectName +
              '/common/backstage/file/fileDownLoad?filePath=' +
              encodeURIComponent(_vm.form.image) ]}}):_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"})],1)],1)]),_c('el-form-item',{attrs:{"label":"是否跳转："}},[_c('el-radio-group',{on:{"change":_vm.changeIsJump},model:{value:(_vm.form.isJump),callback:function ($$v) {_vm.$set(_vm.form, "isJump", $$v)},expression:"form.isJump"}},[_c('el-radio',{attrs:{"label":"是","value":"是"}}),_c('el-radio',{attrs:{"label":"否","value":"否"}})],1)],1),(_vm.form.isJump=='是')?_c('el-form-item',{attrs:{"label":"跳转图片："}},[_c('div',{staticClass:"upload-image"},[_c('el-upload',{staticClass:"avatar-uploader",attrs:{"action":_vm.projectName + '/common/backstage/file/fileupload',"headers":_vm.headers,"show-file-list":false,"on-success":_vm.handleAvatarSuccess1,"before-upload":_vm.beforeAvatarUpload1}},[(_vm.form.jumpUrl)?_c('el-image',{staticClass:"avatar",attrs:{"src":_vm.projectName +
            '/common/backstage/file/fileDownLoad?filePath=' +
            encodeURIComponent(_vm.form.jumpUrl),"preview-src-list":[
            _vm.projectName +
              '/common/backstage/file/fileDownLoad?filePath=' +
              encodeURIComponent(_vm.form.jumpUrl) ]}}):_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"})],1)],1)]):_vm._e()],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){_vm.adDialogVisible = false}}},[_vm._v("取 消")]),(_vm.titleName=='新增广告')?_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.submit}},[_vm._v("确 定")]):_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.editSubmit}},[_vm._v("确 定")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }