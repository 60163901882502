<template>
  <!-- 广告管理 -->
  <div class="container">
    <div class="add-bar">
      <el-button type="primary" @click="addBanner" size="small">新增</el-button>
    </div>
    <el-table :data="tableData" stripe style="100%">
      <el-table-column prop="" label="序号" width="180" type="index"></el-table-column>
      <el-table-column prop="image" label="banner图">
        <template v-slot="scope">
          <el-image
          v-if="scope.row.image"
            :src="
              projectName +
              '/common/backstage/file/fileDownLoad?filePath=' +
              encodeURIComponent(scope.row.image)
            "
            class="image-one"
            :preview-src-list="[
              projectName +
                '/common/backstage/file/fileDownLoad?filePath=' +
                encodeURIComponent(scope.row.image),
            ]"
          >
          </el-image>
          <!-- <img v-if="scope.row.image" :src="projectName + '/common/backstage/file/fileDownLoad?filePath=' + encodeURIComponent(scope.row.image)" class="image-one" /> -->
        </template>
      </el-table-column>
      <el-table-column prop="isJump" label="状态">
        <template v-slot="scope">
          <span>{{scope.row.status==1?'上架':'下架'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="isJump" label="是否跳转">
        <template v-slot="scope">
          <span>{{scope.row.isJump==true?'是':'否'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="jumpUrl" label="跳转图片">
        <template v-slot="scope">
          <el-image
          v-if="scope.row.jumpUrl"
            :src="
              projectName +
              '/common/backstage/file/fileDownLoad?filePath=' +
              encodeURIComponent(scope.row.jumpUrl)
            "
            class="image-two"
            :preview-src-list="[
              projectName +
                '/common/backstage/file/fileDownLoad?filePath=' +
                encodeURIComponent(scope.row.jumpUrl),
            ]"
          >
          </el-image>
          <!-- <img v-if="scope.row.jumpUrl" :src="projectName + '/common/backstage/file/fileDownLoad?filePath=' + encodeURIComponent(scope.row.jumpUrl)" class="image-two" /> -->
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="createTime"
        label="创建时间"
        width="180"
      ></el-table-column> -->
      <el-table-column prop="operation" label="操作" width="180">
        <template slot-scope="scope">
          <!-- <el-button type="text" class="">编辑</el-button> -->
          <!-- <el-button type="text" class="btn-red" >删除</el-button> -->
          <el-link v-if="scope.row.status==1"  :underline="false" type="primary" @click="isGrounding(scope.row)">下架</el-link>
          <el-link v-if="scope.row.status==0" :underline="false" type="danger" @click="isGrounding(scope.row)">上架</el-link>
          <el-link
            :underline="false"
            type="primary"
            class="ml10r"
            @click="editOption(scope.row)"
            >编辑</el-link>
            
          <el-link
            :underline="false"
            type="danger"
            class="ml10r"
            @click="deleteOption(scope.row)"
            >删除</el-link
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="footer">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="listQuery.current"
        :page-size="listQuery.size"
        layout="total, prev, pager, next, jumper"
        :total="listQuery.total"
      >
      </el-pagination>
    </div>
    <!-- 新增弹框 -->
    <div class="dialogVisible">
      <el-dialog :title="titleName" :visible.sync="adDialogVisible" width="30%">
        <div>
          <el-form label-position="right" label-width="130px" v-model="form">
            <el-form-item label="banner图：">
              <div class="upload-image1">
                <el-upload
                  class="avatar-uploader1"
                  :action="projectName + '/common/backstage/file/fileupload'"
                  :headers="headers"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                <el-image
          v-if="form.image"
            :src="
              projectName +
              '/common/backstage/file/fileDownLoad?filePath=' +
              encodeURIComponent(form.image)
            "
            class="avatar1"
            :preview-src-list="[
              projectName +
                '/common/backstage/file/fileDownLoad?filePath=' +
                encodeURIComponent(form.image),
            ]"
          >
          </el-image>
                  <!-- <img v-if="form.image" :src="projectName + '/common/backstage/file/fileDownLoad?filePath=' + encodeURIComponent(form.image)" class="avatar1" /> -->
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </div>
            </el-form-item>

            <!-- <el-form-item label="小程序是否展示：">
              <el-radio-group v-model="form.isShow">
                <el-radio label="是"></el-radio>
                <el-radio label="否"></el-radio>
              </el-radio-group>
            </el-form-item> -->
            <!-- <el-form-item label="跳转路由：">
              <el-input
                placeholder="请输入跳转路由"
                v-model="form.route"
                style="width:320px"
              ></el-input>
            </el-form-item> -->
            <el-form-item label="是否跳转：">
              <el-radio-group v-model="form.isJump" @change="changeIsJump">
                <el-radio label="是" value="是"></el-radio>
                <el-radio label="否" value="否"></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="跳转图片：" v-if="form.isJump=='是'">
              <div class="upload-image">
                <el-upload
                  class="avatar-uploader"
                  :action="projectName + '/common/backstage/file/fileupload'"
                  :headers="headers"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess1"
                  :before-upload="beforeAvatarUpload1"
                >
                <el-image
          v-if="form.jumpUrl"
            :src="
              projectName +
              '/common/backstage/file/fileDownLoad?filePath=' +
              encodeURIComponent(form.jumpUrl)
            "
            class="avatar"
            :preview-src-list="[
              projectName +
                '/common/backstage/file/fileDownLoad?filePath=' +
                encodeURIComponent(form.jumpUrl),
            ]"
          >
          </el-image>
                  <!-- <img v-if="form.jumpUrl" :src="projectName + '/common/backstage/file/fileDownLoad?filePath=' + encodeURIComponent(form.jumpUrl)" class="avatar" /> -->
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="adDialogVisible = false" size="small">取 消</el-button>
          <el-button type="primary" @click="submit" size="small" v-if="titleName=='新增广告'">确 定</el-button>
          <el-button type="primary" @click="editSubmit" size="small" v-else>确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { list,add,deleteAdvert,edit,adStatus } from "../../api/index";
import {projectName} from "../../util/config"
export default {
  data() {
    return {
      tableData: [],
      adDialogVisible: false,
      titleName:"",
      isShow: 1,
      projectName:projectName,
      headers: {sessionId: sessionStorage.getItem("access_token"),},
      form: {
        image:"",//banner图
        jumpUrl:"",
        // isShow: "",
        isJump: "是",
      },
      listQuery:{
        current: 1,
        size: 10,
        total: 0,
      }
    };
  },
  mounted() {
    this.getTableData()
  },
  methods: {
    getTableData() {
      let data = {
        ...this.listQuery
      }
      list(data).then(res=>{
        if(res.code==1) {
          this.tableData = res.data.records || [];
          this.listQuery.total = res.data.total || 0
        }
      })
    },
    isGrounding(row) {
      var status = null;
      if(row.status == 0) {
        status = 1
      } else {
        status = 0
      }
      let data = {
        id:row.id,
        status:status
      }
      adStatus(data).then(res=>{
        if(res.code==1) {
          if(status ==1) {
            this.$message.success("已上架！")
          } else {
            this.$message.success("已下架！")
          }
          this.getTableData()
        }
      })
    },
    handleCurrentChange(current) {
      this.listQuery.current = current;
      this.getTableData()
    },
    addBanner() {
      this.adDialogVisible = true;
      this.titleName = '新增广告'
    },
    handleAvatarSuccess(res, file) {
      console.log(res,file,"handleAvatarSuccess")
      this.form.image = res.data.nginxFileReadPath
    },
    
    beforeAvatarUpload(file) {
      // const isJPG = file.type === "image/jpeg";
      // const isLt2M = file.size / 1024 / 1024 < 2;

      // if (!isJPG) {
      //   this.$message.error("上传头像图片只能是 JPG 格式!");
      // }
      // if (!isLt2M) {
      //   this.$message.error("上传头像图片大小不能超过 2MB!");
      // }
      // return isJPG && isLt2M;
    },
    handleAvatarSuccess1(res, file) {
      this.form.jumpUrl = res.data.nginxFileReadPath
    },
    beforeAvatarUpload1(file) {},
    changeIsJump(val) {
      // console.log(val,"vvv222")
      // if(val=='否') {
      //   this.form.jumpUrl = ""
      // }
    },
    submit() {
      let isJump = true;
      if(this.form.isJump=='是') {
        isJump = true
      } else {
        this.form.jumpUrl = ""
        isJump = false
      }
      if(this.form.isJump=='是' && this.form.jumpUrl =='') {
        this.$message.error("请上传跳转图片");
      }
      let data = {
        ...this.form,
        isJump:isJump
      }
      // console.log(data,"ffffff")
      add(data).then(res=>{
        if(res.code==1) {
          this.$message.success("新增成功！");
          this.adDialogVisible = false;
          this.form = {};
          this.getTableData()
        }
      })
    },
    // 编辑确定
    editSubmit() {
      let isJump = true;
      if(this.form.isJump=='是') {
        isJump = true
      } else {
        this.form.jumpUrl = ""
        isJump = false
      }
      if(this.form.isJump=='是' && this.form.jumpUrl =='') {
        this.$message.error("请上传跳转图片");
      }
      let data = {
        ...this.form,
        isJump:isJump
      }
      edit(data).then(res=>{
        if(res.code==1) {
          this.$message.success("编辑成功！");
          this.adDialogVisible = false;
          this.form = {};
          this.getTableData()
        }
      })
    },
    editOption(row) {
      this.adDialogVisible = true;
      this.titleName = '编辑广告';
      console.log(row,"fffffffff123")
      console.log(row.isJump,"isJump")
      this.form = {...row}
      if(row.isJump) {
        console.log("333333")
        this.form.isJump = '是'
      } else {
        this.form.isJump = '否'
      }
      
    },
    deleteOption(row) {
      this.$confirm("是否确认删除此广告？", "提示", {
        confirmButtonText: "确定",
        concelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteAdvert({id:row.id}).then((res) => {
          if (res.code == 1) {
            this.$message({
              type: "success",
              message: "删除成功！",
            });
            this.getTableData();
          }
        });
      });
    }
  },
};
</script>
<style lang="less" scoped>
.container {
  .add-bar {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
}
.el-button--small {
  width: 80px;
}
.ml10r {
  margin-left: 10px;
}
.dialogVisible {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 278px;
    line-height: 278px;
    text-align: center;
    border: 1px dashed;
  }
  .avatar {
    width: 178px;
    height: 278px;
    display: block;
  }
  .upload-image1 {
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 300px;
      height: 78px;
      line-height: 78px;
      text-align: center;
      border: 1px dashed;
    }
    .avatar1 {
      width: 300px;
      height: 78px;
      display: block;
    }
  }
  
  
}
.footer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
.image-one {
  width:250px;
  height: 70px;
}
.image-two {
  width: 80px;
  height: 150px;
}
</style>
